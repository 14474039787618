import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  device: PropTypes.string,
  isIos: PropTypes.bool,
  imgPath: PropTypes.string,
  visible: PropTypes.bool,
  setRef: PropTypes.func,
};

const defaultProps = {
  device: '',
  isIos: false,
  imgPath: '',
  visible: false,
  setRef: null,
};

const CasaApp = ({
  device,
  isIos,
  imgPath,
  visible,
  setRef,
}) => {
  const elRef = setRef;
  return (
    <section
      ref={elRef}
      id="casaapp"
      className={`homeslice${visible ? ' withImages' : ''}`}
    >
      <div className="sw">
        <div className="align">
          <h2 className="hpSectTitle is-rel">
            Scarica l’app di Casa.it
          </h2>
          <p className="tp-s--x2l tp-w--m c-txt--f2">
            <a
              className="c-txt--f2"
              href="https://www.casa.it/casait/app-annunci-casa/"
              title="Scarica l'app di Casa.it"
            >
              Cerca tra migliaia di annunci, salva i tuoi immobili preferiti e ricevi notifiche immediate sugli annunci appena pubblicati.
            </a>
          </p>
          {
            device !== ''
              && device !== 'desktop'
              ? isIos ? (
                <a
                  href="https://apps.apple.com/app/apple-store/id470456810?pt=127572684&ct=web-casa_downloadApp&mt=8"
                  title="Scarica l'app di Casa.it dall'App Store"
                  className="deviceButton ios"
                >
                  <img className="btn-app-store" src={visible ? `${imgPath}app-store.svg` : null} alt="Scarica su App Store" />
                </a>)
                :
                (
                  <>
                    <a
                      href="https://play.google.com/store/apps/details?id=it.casa.app&utm_source=web-casa&utm_campaign=downloadApp&utm_medium=direct"
                      title="Scarica l'app di Casa.it da Google Play"
                      className="deviceButton android"
                    >
                      <img className="btn-app-store" src={visible ? `${imgPath}google-play.svg` : null} alt="Scarica su Google Play" />
                    </a>
                  </>
                )
              : (
                <div className="deviceButtons">
                  <a
                    href="https://apps.apple.com/app/apple-store/id470456810?pt=127572684&ct=web-casa_downloadApp&mt=8"
                    title="Scarica l'app di Casa.it dall'App Store"
                    className="deviceButton ios"
                  >
                    <img className="btn-app-store" src={visible ? `${imgPath}app-store.svg` : null} alt="Scarica su App Store" />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=it.casa.app&utm_source=web-casa&utm_campaign=downloadApp&utm_medium=direct"
                    title="Scarica l'app di Casa.it da Google Play"
                    className="deviceButton android ml--m"
                  >
                    <img className="btn-app-store" src={visible ? `${imgPath}google-play.svg` : null} alt="Scarica su Google Play" />
                  </a>
                </div>
              )
          }
        </div>
        <div className="bigImg is-rel">
          <div className="qrcode c-bg--w" />
        </div>
      </div>
    </section>
  );
};
CasaApp.propTypes = propTypes;
CasaApp.defaultProps = defaultProps;
export default CasaApp;
