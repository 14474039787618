/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PAGES_TYPE from 'shared-constants/src/pagesType';
import casaClaim from 'shared-constants/src/header_footer/casaClaim';

import makeClassName from 'shared-utils/src/makeClassName';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents';
import getShouldForceSynch from 'shared-utils/src/helpersMyCasa/shouldForceSynch';

import LazyLoad from 'shared-components/components/LazyLoad';

import GoToTop from '@async-components/GoToTop';

import reducerSearch from '@state/reducers/search';
import selectorSearch from '@state/selectors/search';

import SectionPublish from '@components/homePage/SectionPublish';

import { CtxContainer } from '@client/app/Container';

import { getActiveFilters, getActiveFiltersCountWithPTypes } from '@filters/helpers';

import App from '../app/App';
import MetaInfo from '../components/common/metainfo/MetaInfo';
import Search from '../components/homePage/Search';
import SearchChildrenLocalities from '../components/homePage/SearchChildrenLocalities';
import ResetButton from '../components/homePage/ResetButton';
import FiltersButton from '../components/homePage/FiltersButton';
import Select from '../components/homePage/Select';
import PTypes from '../components/homePage/Ptypes';
import SearchButton from '../components/homePage/SearchButton';
import AnimatedSplash from '../components/homePage/AnimatedSplash';
import CasaForYou from '../components/homePage/CasaForYou';
import CasaSEOPresentation from '../components/homePage/CasaSEOPresentation';
import CasaApp from '../components/homePage/CasaApp';
import BlogPosts from '../components/homePage/BlogPosts';
import SeoFooter from '../components/homePage/SeoFooter';
import RecentSearches from './RecentSearches';
import AuctionSplash from '../components/homePage/AuctionSplash/index';

import {
  isChildOf,
  scrollToTop,
} from '../helpers/DOMHelpers';

import { callGetAddresses } from '../helpers/MyCasaHelpers';

import './HomePageSearch-critical.scss';
import './HomePageMore-critical.scss';

import {
  categoryFilter,
  channelFilter,
  casaForYouContentList,
  casaPresentationContentList,
} from '../constants/restyling';

class Home extends PureComponent {
  static contextType = CtxContainer;

  constructor(props) {
    super(props);
    this.state = {
      availablePoi: props.home.availablePoi,
      openSelect: null,
      loadCasaAppImg: false,
      config: props.config,
      resetButtonCoords: {
        top: null,
        left: null,
        mobLeft: null,
      },
      favoriteAddresses: null,
    };
  }

  componentDidMount() {
    this.channelSelect = document.querySelector('#channelSelect');
    this.locationInput = document.querySelector('#locationInput');
    this.searchButtonEl = document.querySelector('#searchButton');
  }

  componentWillReceiveProps(nextProps) {
    const {
      device,
      home,
      availablePois,
      mainLocation,
      filters,
      viewport,
      user,
      user: {
        isAuthenticated: newAuthState,
      }
    } = nextProps;

    const {
      availablePois: oldAvailablePois,
      mainLocation: oldMainLocation,
      filters: oldFilters,
      viewport: oldViewport,
      user: {
        isAuthenticated: oldAuthState,
      }
    } = this.props;        

    if (device === 'desktop') {
      window.addEventListener('click', evt => this.searchElementsBehaviour(evt));
    } else {
      document.documentElement.style.setProperty('--minHeight', `${viewport.height}px`);
    }

    if (!oldAuthState && newAuthState) {
      const refreshBookmarks = getShouldForceSynch();
      callGetAddresses(user, refreshBookmarks, 'home', (favoriteAddresses) => {
        this.setState({
          favoriteAddresses,
        });
      });
    }

    if (oldViewport.width !== viewport.width) {
      this.updateLayoutBounduaries();
    }

    if (!home.isFetchingResults) {
      if (home.availablePoi) {
        this.setState({
          availablePoi: home.availablePoi,
        });
      } else {
        this.setState({
          availablePoi: [],
        });
      }
    }

    if (availablePois !== oldAvailablePois) {
      if (availablePois) {
        this.setState({
          availablePoi: availablePois,
        });
      } else {
        this.setState({
          availablePoi: [],
        });
      }
    }

    if (!oldMainLocation && mainLocation) {
      this.computeLayoutBounduaries(true, filters.propertyTypes.length > 0);
    }

    if (
      oldMainLocation
      && (
        oldFilters.channel !== filters.channel
        || oldFilters.category !== filters.category
        || oldFilters.propertyTypes !== filters.propertyTypes
      )
    ) {
      this.computeLayoutBounduaries(false, filters.propertyTypes.length > 0);
    }
  }

  updateLayoutBounduaries = () => {
    const { filters } = this.props;
    this.computeLayoutBounduaries(false, filters.propertyTypes.length > 0);
  }

  computeLayoutBounduaries = (render = false, hasPtypes = false) => {
    const timing = render
      ? 75
      : 10;

    setTimeout(() => {
      const cont = document.querySelector('#searchContainer');
      const elements = document.querySelectorAll('.hpFormOption');
      const firstSelect = document.querySelectorAll('.hpFormSelect')[hasPtypes ? 1 : 0];

      const contW = cont.getBoundingClientRect().width;
      const adjust = (document.documentElement.clientWidth - contW) / 2;

      const yCoords = [];
      const xCoords = [];

      elements.forEach(
        (el) => {
          const { top, left, width } = el.getBoundingClientRect();
          top && yCoords.push(Math.ceil(top));
          left && xCoords.push(Math.ceil(left - adjust) + Math.ceil(width + 16));
        },
      );

      const top = `${Math.min(...yCoords) - 24}px`;
      let left = Math.max(...xCoords) - 55 - 16;
      if (left + 110 > document.documentElement.clientWidth - (document.documentElement.clientWidth - contW)) {
        left -= 55;
      }

      const mob = firstSelect.getBoundingClientRect();
      let mobLeft = Math.ceil(mob.left - adjust - 16) + Math.ceil(mob.width) - 55;
      if (mobLeft + 110 > contW - 32) {
        mobLeft = contW - 32 - 110;
      }

      this.setState({
        resetButtonCoords: {
          top,
          left: `${left}px`,
          mobLeft: `${mobLeft}px`,
        },
      });
    }, timing);
  };

  /* LAYOUT BEHAVIOUR */

  /**
    * @desc search elements behaviour on user clicks: closes open select when the user clicks outside of it or removes focus on input field when the user clicks outside of it
    *
    * @param object $evt - click event
    *
    * @fires setState
  */

  searchElementsBehaviour = (evt) => {
    const { openSelect } = this.state;    
    const categorySelect = document.querySelector('#categorySelect');

    let condition = null;
    if (categorySelect) {
      condition = (
        openSelect !== null
        && !isChildOf(evt.target, document.querySelector('#categorySelect'))
        && !isChildOf(evt.target, document.querySelector('#channelSelect'))
      );
    } else {
      condition = (
        openSelect !== null
        && !isChildOf(evt.target, document.querySelector('#channelSelect'))
      );
    }

    if (condition) {      
      this.setState({ openSelect: null });
    }
  }

  /**
    * @desc toggles clicked select & closes the open select if it's different from clicked one & removes focus on input field
    *
    * @param string $which - name of the filter updated by select
    *
    * @fires setState
  */

  onToggleSelect = (which) => {
    const { openSelect } = this.state;
    if (openSelect !== null) {      
      if (which !== openSelect) {        
        this.setState({
          openSelect: which,
        });
      } else {
        this.setState({
          openSelect: null,
        });
      }
    } else {      
      this.setState({
        openSelect: which,
      });
    }
  }

  storeSelectValue = (newFilters) => {
    const { setFilters } = this.props;
    setFilters(newFilters);
    this.setState({ openSelect: null });
  }

  updateGlobalState = (state, clb) => this.setState(state, clb);

  globalReducer = async (type, payload) => {
    const newState = await reducerSearch(this.state, type, payload);
    this.setState(newState);
  }

  globalSelector = type => selectorSearch(this.state, type)

  ptypesSelectFromModal = newVal => this.globalReducer('ptypesSelectFromModal', newVal);

  render() {
    const {
      device,
      viewport,
      mobileOs,
      orientation,
      isSafari,
      config: {
        siteUrl,
        smartSuggest,
        api: {
          account,
        },
        apiEndPoints: {
          mycasaUser,
        },
        mycasaLinks,
        remote: {
          LocalitiesWithAgencyZones,
        },
      },
      home,
      home: {
        ogData,
        domain,
        blogPosts,
        seoFooter,
        seoFooterRent,
        seoFooterBigCities,
        categoryMenuValues,
        pTypesMenuValues,
        totalSeoFooter,
        numRoomMenuValues,
        licenseTypeGroupMenuValues,
        isSEOFooterHP,
        ptgrpsAggregates,
      },
      user,
      modal,
      modalData,
      seoData,

      filters,
      filters: {
        channel,
        category,
      },
      locations,
      parentLocation,
      mainLocation,
      additionalLocations,
      nearbyShape,
      setFilters,
      setLocations,
      setNearby,
      submit,
      resetAll,
      getAvailableResults,
      recentSearches,
      isFetching,
      availableResults,
      hasLSSearch,
      filtersDialog,
      setFiltersDialog,
      setResetDialog,
      setMapDialog,
      startWithSelectedPtypes,
      isLocalStorageAvailable,
      setEraseAddressesDialog,
    } = this.props;

    const {
      openSelect,
      resetButtonCoords,
    } = this.state;

    const pageData = {
      ogData: ogData || [],
      filtersDialog,
      filtersProps: {
        device,
        viewport,
        orientation,
        mobileOs,
        isSafari,
        isAgencyHome: false,
        isPublisherPage: false,
        isPublisherHP: false,
        isAgencySrp: false,

        publisherName: null,
        publisherId: null,
        publisherLocs: [],
        publisherLocsNumber: 0,

        agencyFreetext: null,
        localitiesWithAgencyZones: LocalitiesWithAgencyZones,

        filters: {
          ...filters,
          channel: filters.channel === 'affitto' ? 'affitti' : filters.channel,
        },
        nearbyShape,
        locations,
        parentLocation,
        mainLocation,
        additionalLocations,
        selNearBy: filters.nearby,
        setFilters,
        setLocations,
        setCurrentNearby: setNearby,
        availableResults,
        getAvailableResults,
        ptgrpsAggregates,
        submitSearch: submit,
        getActiveFilters,
        getActiveFiltersCountWithPTypes,
        hpValuesReset: resetAll,
        startWithSelectedPtypes,
        ptypesSelectFromModal: this.ptypesSelectFromModal,
        setEraseAddressesDialog,
      },
      openSelect,
    };

    const appProps = {
      ...this.props,
      pageData,
      pageType: PAGES_TYPE.HOME_PAGE,
      updateGlobalState: this.updateGlobalState,
      globalState: this.state,
      globalReducer: this.globalReducer,
      globalSelector: this.globalSelector,
      trackEvents: trackGTMEvents,
      isLocalStorageAvailable,
      isAuthenticated: user.isAuthenticated,
      userIsChecked: user.checked,      
    };

    const logo = isSEOFooterHP
      ? (
        <a
          href={`${siteUrl}/`}
          title={casaClaim}
          className="logo"
        >
          {casaClaim}
        </a>
      ) : (
        <span className="logo">
          {casaClaim}
        </span>
      );

    const searchContainerClassName = makeClassName([
      device === 'tablet' && 'tablet',
      openSelect !== null && 'choosing',
      (category.length + channel.length >= 17) && 'centered',
    ]);

    const onlyAuction = home?.filters?.only_auction;    

    return (
      <App {...appProps}>
        <MetaInfo
          domain={domain}
          seoData={seoData}
          homeRent={home.filters.tr === 'affitti'}
        />
        <div
          id="splash"
          className={onlyAuction ? 'auctionHP' : category}
          onClick={e => device !== 'desktop'
            ? this.searchElementsBehaviour(e)
            : null
          }
        >
          <AnimatedSplash category={onlyAuction ? 'aste' : category} />
          {
            onlyAuction ? (
              <AuctionSplash total={totalSeoFooter} />
            )
              : (
                <>
                  <div className={openSelect !== null ? 'identity short' : 'identity'}>                  
                    {logo}
                    <p>La casa per te è qui</p>
                  </div>
                  <div
                    id="searchContainer"
                    className={searchContainerClassName}
                  >
                    <ResetButton
                      locations={locations}
                      filters={filters}
                      reset={resetAll}
                      setResetDialog={setResetDialog}
                      coords={resetButtonCoords}
                    />
                    {filters.propertyTypes.length === 0 ? (
                      <Select
                        scope="category"
                        label="Cerco"
                        value={category}
                        matchValue={channel}
                        values={categoryFilter}
                        isOpen={openSelect === 'category'}
                        toggleSelect={this.onToggleSelect}
                        storeSelectValue={this.storeSelectValue}
                        visible
                        availableResults={availableResults}
                        activeFilters={getActiveFilters(filters)}
                        device={device}
                      />
                    ) : (
                      <PTypes
                        propertyTypes={filters.propertyTypes}
                        licenseTypeGroups={filters.license_type_groups}
                        availableResults={availableResults}
                      />
                    )}
                    <Select
                      scope="channel"
                      label="in"
                      value={channel}
                      matchValue={category}
                      values={channelFilter}
                      isOpen={openSelect === 'channel'}
                      toggleSelect={this.onToggleSelect}
                      storeSelectValue={this.storeSelectValue}
                      device={device}
                    />
                    <Search
                      device={device}
                      mainLocation={mainLocation}
                      filters={filters}
                      smartSuggest={smartSuggest}
                      setLocations={setLocations}
                      setCurrentNearby={setNearby}
                      submitSearch={submit}
                      setMapDialog={setMapDialog}
                    />
                    {additionalLocations && (
                      <SearchChildrenLocalities
                        filters={filters}
                        locations={additionalLocations}
                        parentLocation={mainLocation}
                        setLocations={setLocations}
                        submitSearch={submit}
                      />
                    )}
                    <FiltersButton
                      filters={filters}
                      locations={locations}
                      hasLSSearch={hasLSSearch}
                      submitSearch={submit}
                      setDialog={setFiltersDialog}
                      updateFilters={setFilters}
                    />
                    <SearchButton
                      device={device}
                      category={category}
                      categoryValues={categoryFilter}
                      availableResults={availableResults}
                      submitSearch={submit}
                      isFetching={isFetching}
                      locations={locations}
                      filters={filters}
                    />
                  </div>
                  {recentSearches && <RecentSearches searches={recentSearches} />}
                </>)
          }
        </div>
        {
          !onlyAuction && (
            <div ref={(node) => { this.publishBand = node; }}>
              <SectionPublish action={user.isAuthenticated ? `${siteUrl}/pubblica/annunci` : `${siteUrl}/pubblica-annunci`} />
            </div>
          )
        }
        {
          !isSEOFooterHP
          && (
            <section
              id="casaforyou"
              className="homeslice"
            >
              <CasaForYou
                device={device}
                viewport={viewport}
                contentList={casaForYouContentList}
                trackEvents={trackGTMEvents}
                user={user}
                userUrls={{
                  accounthost: account,
                  userhost: mycasaUser,
                  links: mycasaLinks,
                }}
                siteUrl={siteUrl}
              />
            </section>
          )
        }
        {
          !isSEOFooterHP
          &&
          <LazyLoad>
            {(visible, setRef) => (
              <CasaApp
                device={device}
                isIos={mobileOs === 'ios'}
                visible={visible}
                setRef={setRef}
                imgPath="https://www.casa.it/external-resources/common_images/common/"
              />
            )}
          </LazyLoad>
        }
        <section
          id="casapresentation"
          className={isSEOFooterHP ? 'homeslice seofooter' : 'homeslice'}
        >
          <CasaSEOPresentation
            baseUrl={appProps.config.baseUrl}
            device={device}
            contentList={casaPresentationContentList}
          />
        </section>
        {
          !isSEOFooterHP
          && blogPosts
          && blogPosts.length > 0
          &&
          <LazyLoad>
            {(visible, setRef) => (
              <BlogPosts
                device={device}
                viewport={viewport}
                blogList={blogPosts}
                setRef={setRef}
                loadBlogImages={visible}
              />
            )}
          </LazyLoad>

        }
        <SeoFooter
          device={device}
          orientation={orientation}
          channel={home.filters.tr}
          category={home.filters.category}
          propertyType={home.filters.propertyType}
          numRooms={home.filters.numRooms}
          buildingCondition={'buildingCondition' in home.filters ? home.filters.buildingCondition : null}
          onlyAuction={home.filters.only_auction}
          seoFooter={seoFooter}
          seoFooterRent={seoFooterRent}
          categoryMenuValues={categoryMenuValues}
          pTypesMenuValues={pTypesMenuValues}
          numRoomMenuValues={numRoomMenuValues}
          licenseTypeGroupMenuValues={licenseTypeGroupMenuValues}
          seoFooterBigCities={seoFooterBigCities}
        />
        {(device !== '' && device !== 'smartphone') && <GoToTop action={scrollToTop} vh={viewport?.height || 0} />}
      </App>
    );
  }
}

export default Home;

Home.propTypes = {
  device: PropTypes.string,
  viewport: PropTypes.instanceOf(Object),
  isLocalStorageAvailable: PropTypes.bool,
  mobileOs: PropTypes.string,
  orientation: PropTypes.string,
  home: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  actions: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  userLogIn: PropTypes.func,
  userLogOut: PropTypes.func,
  modal: PropTypes.bool,
  modalData: PropTypes.instanceOf(Object),
  seoData: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object),
};

Home.defaultProps = {
  device: '',
  viewport: {},
  isLocalStorageAvailable: false,
  mobileOs: '',
  orientation: '',
  home: {},
  config: {},
  location: {},
  actions: {},
  user: {},
  userLogIn: () => { },
  userLogOut: () => { },
  modal: false,
  modalData: {},
  seoData: {},
  intl: {},
};
